import React, { useEffect, useState } from "react";
import { BlogPosts } from "../blog";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import parse from "html-react-parser";
import Toast from "../../components/swall";

function BlogPost() {
  const { slug } = useParams();
  const [post, setPost] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    api
      .getData(`/web-content/article/detail/${slug}`)
      .then((res) => {
        setPost(res.data.data.article);
        setIsLoading(false);
      })
      .catch((err) => {
        Toast.fire({
          icon: "warning",
          text:"Kesalahan jaringan, silahkan refresh halaman ini."
        });
      });
  }, []);

  return (
    <section class="bg-white dark:bg-gray-900 mt-28">
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="grid gap-8">
          <header class=" not-format">
            <address class="flex items-center mb-6 not-italic">
              <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                <svg
                  class="w-16 h-16 mr-4 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                </svg>
                <div>
                  <a
                    href="#"
                    rel="author"
                    class="text-xl font-bold text-gray-900 dark:text-white"
                  >
                    {post.written_by}
                  </a>
                  <p class="text-base font-light text-gray-500 dark:text-gray-400">
                    Admin Transpublika.com
                  </p>
                  <p class="text-base font-light text-gray-500 dark:text-gray-400">
                    <time
                      pubdate
                      datetime="2022-02-08"
                      title="February 8th, 2022"
                    >
                      {post.human_date}
                    </time>
                  </p>
                </div>
              </div>
            </address>
          </header>
          {post.content && (
            <article className="mx-auto w-full max-w-screen format format-sm sm:format-base lg:format-lg format-blue dark:format-invert prose">
              {parse(post.content)}
            </article>
          )}
        </div>
      </div>
    </section>
  );
}

export default BlogPost;
